// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyC32zYJGtGur4msPonKG2l-tXIkpnjm0J4",
  authDomain: "valet-hero.firebaseapp.com",
  projectId: "valet-hero",
  storageBucket: "valet-hero.appspot.com",
  messagingSenderId: "1074917246738",
  appId: "1:1074917246738:web:02980ad4a7406068db55cb",
  measurementId: "G-LPC6GHTYKF"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const auth = getAuth(app);

export { db, auth, storage, functions };
// src/App.js

import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import BinTagsTable from './components/communities/BinTagsTable';

const Login = lazy(() => import('./pages/Login'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Onboarding = lazy(() => import('./pages/Onboarding'));
const Pricing = lazy(() => import('./pages/Pricing'));
const Home = lazy(() => import('./pages/Home'));
const Communities = lazy(() => import('./pages/Communities'));
const CommunityProfile = lazy(() => import('./pages/CommunityProfile'));
const Team = lazy(() => import('./pages/Team'));
const TeamProfile = lazy(() => import('./pages/TeamProfile'));
const Tracking = lazy(() => import('./pages/Tracking'));
const Schedule = lazy(() => import('./pages/Schedule'));
const Settings = lazy(() => import('./pages/Settings'));
const PublicCommunityProfile = lazy(() => import('./pages/PublicCommunityProfile'));
const BintagRedirect = lazy(() => import('./components/BintagRedirect'));

// Import the report pages from the masters directory
const ShiftReports = lazy(() => import('./pages/masters/ShiftReport'));
const ClockInOuts = lazy(() => import('./pages/masters/ClockInOuts'));
const CheckPoint = lazy(() => import('./pages/masters/CheckPoint'));
const UnitsPage = lazy(() => import('./pages/masters/Units'));
const ViolationsPage = lazy(() => import('./pages/masters/ViolationsPage'));
const AllViolations = lazy(() => import('./pages/AllViolations'));
const AllServiceLogs = lazy(() => import('./pages/AllServiceLogs'));

const WaitlistPage = lazy(() => import('./pages/Waitlist'));

const PropertyAdmin = lazy(() => import('./pages/PropertyAdmin'));

const NotFound = lazy(() => import('./pages/NotFound'));

const App = ({ user, onboardingComplete, planSelected, isTeamMember }) => {
  return (
    <Router>
      <Suspense fallback={null}>
        <Routes>
          <Route path="/login" element={user ? <Navigate to={onboardingComplete ? "/" : "/onboarding"} /> : <Login />} />
          <Route path="/onboarding" element={user && !isTeamMember ? <Onboarding /> : <Navigate to="/login" />} />
          <Route path="/pricing" element={user && !isTeamMember ? <Pricing /> : <Navigate to="/login" />} />
          <Route path="*" element={<Navigate to={user ? (onboardingComplete ? "/" : "/onboarding") : "/login"} />} />
          <Route path="/" element={user ? (onboardingComplete ? <Dashboard /> : <Navigate to="/onboarding" />) : <Navigate to="/login" />}>
            <Route index element={<Home />} />
            <Route path="communities" element={<Communities />} />
            <Route path="communities/:propertyName" element={<CommunityProfile />} />
            <Route path="team" element={<Team />} />
            <Route path="team/:employeeId" element={<TeamProfile />} />
            <Route path="tracking" element={<Tracking />} />
            <Route path="schedule" element={<Schedule />} />
            <Route path="settings" element={<Settings />} />
            <Route path="shift-report" element={<ShiftReports />} />
            <Route path="clock-in-outs" element={<ClockInOuts />} />
            <Route path="checkpoints" element={<CheckPoint />} />
            <Route path="units" element={<UnitsPage />} />
            <Route path="violations" element={<ViolationsPage />} />
            <Route path="/communities/:communityName/bintags" element={<BinTagsTable user={user} />} />
          </Route>
          <Route 
            path="/:businessName/:communityName" 
            element={<PublicCommunityProfile />} 
          />
          <Route 
            path="/:businessName/:communityName/bintag/:bintagId" 
            element={<BintagRedirect />} 
          />
          <Route 
            path="/:businessName/:communityName/admin" 
            element={<PropertyAdmin />} 
          />
          <Route 
            path="/:businessName/:communityName/admin/violations" 
            element={<AllViolations />} 
          />
          <Route 
            path="/:businessName/:communityName/admin/service-logs" 
            element={<AllServiceLogs />} 
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;

import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from './firebase';
import { doc, getDoc, collection, collectionGroup, query, where, getDocs, updateDoc, writeBatch } from 'firebase/firestore';
import App from './App';

const AppWrapper = () => {
  const [user, loading, error] = useAuthState(auth);
  const [onboardingComplete, setOnboardingComplete] = useState(false);
  const [planSelected, setPlanSelected] = useState(false);
  const [onboardingChecked, setOnboardingChecked] = useState(false);
  const [isTeamMember, setIsTeamMember] = useState(false);

  useEffect(() => {
    const checkUserStatus = async () => {
      if (user) {
        console.log("Checking user status for user:", user.uid);

        // Create a single batch query
        const batch = writeBatch(db);
        const [teamMemberSnapshot, customerDoc] = await Promise.all([
          getDocs(query(collectionGroup(db, 'team'), where('id', '==', user.uid))),
          getDoc(doc(db, 'customers', user.uid))
        ]);
        
        if (!teamMemberSnapshot.empty) {
          console.log("User is a team member:", user.uid);
          setIsTeamMember(true);
          setOnboardingChecked(true);
          return;
        }

        if (customerDoc.exists()) {
          const userData = customerDoc.data();
          console.log("User document exists:", userData);
          setOnboardingComplete(userData.onboardingComplete);

          // Check if user is in trial period
          const accountCreatedDate = userData?.dateCreated?.toDate();
          if (accountCreatedDate) {
            const trialEndDate = new Date(accountCreatedDate);
            trialEndDate.setDate(trialEndDate.getDate() + 14);
            const isInTrialPeriod = new Date() < trialEndDate;
            
            if (isInTrialPeriod) {
              setPlanSelected(true); // Consider trial as an active plan
              setOnboardingChecked(true);
              return;
            }
          }

          const subscriptionsRef = collection(db, 'customers', user.uid, 'subscriptions');
          const activeSubscriptionsQuery = query(subscriptionsRef, where('status', '==', 'active'));
          const activeSubscriptionsSnap = await getDocs(activeSubscriptionsQuery);

          setPlanSelected(!activeSubscriptionsSnap.empty);
        } else {
          console.log("No user document found for user:", user.uid);
          setOnboardingComplete(false);
          setPlanSelected(false);
        }
        setOnboardingChecked(true);
      } else {
        setOnboardingChecked(false);
      }
    };

    checkUserStatus();
  }, [user]);

  if (loading || (user && !onboardingChecked)) {
    return null; // Don't render anything until loading is complete and onboarding status is checked
  }

  if (error) {
    console.error("Authentication Error: ", error);
    return <div>Error loading the application.</div>;
  }

  return <App user={user} onboardingComplete={onboardingComplete} planSelected={planSelected} isTeamMember={isTeamMember} />;
};

export default AppWrapper;

import React from 'react';
import { helix } from 'ldrs'
import './Loader.css'; // Create this file for loader styles

helix.register()

const Loader = () => {
  return (
    <div className="loader-container">
      <l-helix
        size="45"
        speed="2.5" 
        color="#c6ff00" 
      ></l-helix>
    </div>
  );
};

export default Loader;
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, getDocs, updateDoc, deleteDoc, doc, addDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import Modal from '../../components/Modal';
import { format } from 'date-fns';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import Loader from '../../components/Loader';
import QRCode from 'qrcode';
import deleteIcon from '../../assets/neon-trash.svg';
import addIcon from '../../assets/add.svg';
import filterIcon from '../../assets/filter.svg';
import '../../styles/BinTags.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copyIcon from '../../assets/copy.svg';
import downloadIcon from '../../assets/neon-download.svg';

const StyledChip = styled(Chip)(({ theme, active }) => ({
  backgroundColor: active ? '#C6FF00' : '#F44336',
  color: '#000000',
  '&.MuiChip-root': {
    height: '24px',
  },
  '&.MuiChip-label': {
    padding: '0 8px',
  },
}));

const columns = [
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'id', headerName: 'ID', flex: 1 },
  { field: 'createdAt', headerName: 'Created At', flex: 1 },
  { field: 'active', headerName: 'Status', flex: 1 },
];

const BinTagsTable = ({ user }) => {
  const { communityName } = useParams();
  const [binTags, setBinTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBinTags, setSelectedBinTags] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [newBinTagName, setNewBinTagName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterActive, setFilterActive] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [selectedBinTag, setSelectedBinTag] = useState(null);
  const [copySuccess, setCopySuccess] = useState('');
  const [activeStatus, setActiveStatus] = useState(false);
  const [originalActiveStatus, setOriginalActiveStatus] = useState(false);
  const [changesMade, setChangesMade] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!user || !communityName) return;

      setLoading(true);
      try {
        const binTagsCollection = collection(db, 'customers', user.uid, 'communities', communityName, 'binTags');
        const binTagsSnapshot = await getDocs(binTagsCollection);
        const binTagsData = binTagsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          createdAt: format(doc.data().createdAt.toDate(), 'MM/dd/yyyy'),
        }));
        setBinTags(binTagsData);

        const userDocRef = doc(db, 'customers', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setBusinessName(userDocSnap.data().businessName || '');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [communityName, user]);

  const handleActivateDeactivate = async (activate) => {
    for (const binTagId of selectedBinTags) {
      const binTagRef = doc(db, 'customers', user.uid, 'communities', communityName, 'binTags', binTagId);
      await updateDoc(binTagRef, { active: activate });
    }
    const updatedBinTags = binTags.map((binTag) =>
      selectedBinTags.includes(binTag.id) ? { ...binTag, active: activate } : binTag
    );
    setBinTags(updatedBinTags);
    setSelectedBinTags([]);
  };

  const handleDelete = async () => {
    setModalOpen(true);
  };

  const confirmDelete = async () => {
    for (const binTagId of selectedBinTags) {
      const binTagRef = doc(db, 'customers', user.uid, 'communities', communityName, 'binTags', binTagId);
      await deleteDoc(binTagRef);
    }
    const updatedBinTags = binTags.filter((binTag) => !selectedBinTags.includes(binTag.id));
    setBinTags(updatedBinTags);
    setSelectedBinTags([]);
    setModalOpen(false);
  };

  const handleAddBinTag = async (e) => {
    e.preventDefault();
    const trimmedBinTagName = newBinTagName.trim();
    const existingBinTag = binTags.find((binTag) => binTag.name === trimmedBinTagName);

    if (existingBinTag) {
      alert(`A checkpoint with the name "${trimmedBinTagName}" already exists.`);
      return;
    }

    if (user && businessName) {
      const binTagsCollection = collection(db, 'customers', user.uid, 'communities', communityName, 'binTags');
      const docRef = await addDoc(binTagsCollection, {
        name: trimmedBinTagName,
        createdAt: serverTimestamp(),
        active: false,
        building: communityName,
      });

      const url = `${window.location.origin}/${businessName}/${communityName}/bintag/${docRef.id}`;
      await updateDoc(docRef, { url });

      const qrCodeURL = await generateQRCodeURL(url);
      await updateDoc(docRef, { qrCodeURL });

      const newBinTag = {
        id: docRef.id,
        name: trimmedBinTagName,
        createdAt: format(new Date(), 'MM/dd/yyyy'),
        active: false,
        building: communityName,
        url,
        qrCodeURL,
      };

      setBinTags([...binTags, newBinTag]);
      setNewBinTagName('');
      setModalOpen(false);
    }
  };

  const generateQRCodeURL = async (url) => {
    try {
      const qrCodeDataURL = await QRCode.toDataURL(url);
      return qrCodeDataURL;
    } catch (error) {
      console.error('Error generating QR code:', error);
      return '';
    }
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    
    const visibleBinTagIds = binTags
      .filter(binTag => 
        binTag.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .map(binTag => binTag.id);

    if (isChecked) {
      setSelectedBinTags(visibleBinTagIds);
    } else {
      setSelectedBinTags([]);
    }
  };

  const handleSelectBinTag = (binTagId) => {
    setSelectedBinTags(prevSelected => {
      const newSelected = prevSelected.includes(binTagId)
        ? prevSelected.filter(id => id !== binTagId)
        : [...prevSelected, binTagId];
      
      const visibleBinTagIds = binTags
        .filter(binTag => 
          binTag.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .map(binTag => binTag.id);
      setSelectAll(visibleBinTagIds.every(id => newSelected.includes(id)));

      return newSelected;
    });
  };

  const handleFilter = () => {
    setFilterActive(filterActive === null ? true : (filterActive ? false : null));
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedBinTags = React.useMemo(() => {
    let sortableBinTags = [...binTags];
    if (sortConfig.key !== null) {
      sortableBinTags.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableBinTags;
  }, [binTags, sortConfig]);

  const filteredSortedBinTags = sortedBinTags
    .filter(binTag => binTag.name.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter(binTag => filterActive === null ? true : binTag.active === filterActive);

  const handleBinTagClick = (binTag) => {
    setSelectedBinTag(binTag);
    setActiveStatus(binTag.active);
    setOriginalActiveStatus(binTag.active);
    setChangesMade(false);
  };

  const generatePDF = () => {
    import('jspdf').then(({ default: jsPDF }) => {
      import('qrcode').then((QRCode) => {
        const doc = new jsPDF({
          orientation: 'portrait',
          unit: 'in',
          format: 'letter'
        });

        const pageWidth = 8.5;
        const pageHeight = 11;
        const margin = 0.5;
        const qrSize = 4;
        const spacing = 0.5;

        let x = margin;
        let y = margin;

        const selectedBinTagsData = binTags.filter(binTag => selectedBinTags.includes(binTag.id));

        selectedBinTagsData.forEach((binTag, index) => {
          if (y + qrSize + spacing > pageHeight - margin) {
            doc.addPage();
            x = margin;
            y = margin;
          }

          QRCode.toDataURL(binTag.url, { width: 300, margin: 0 }, (err, url) => {
            if (err) throw err;

            doc.addImage(url, 'PNG', x, y, qrSize, qrSize);
            doc.setFontSize(12);
            doc.text(binTag.name, x + qrSize / 2, y + qrSize + 0.2, { align: 'center' });

            x += qrSize + spacing;
            if (x + qrSize > pageWidth - margin) {
              x = margin;
              y += qrSize + spacing + 0.5;
            }

            if (index === selectedBinTagsData.length - 1) {
              doc.save('selected_bin_tags_qr_codes.pdf');
            }
          });
        });
      });
    });
  };

  const handleStatusChange = () => {
    const newStatus = !activeStatus;
    setActiveStatus(newStatus);
    setChangesMade(newStatus !== originalActiveStatus);
  };

  const handleSaveChanges = async () => {
    if (selectedBinTag) {
      try {
        const binTagRef = doc(
          db,
          'customers',
          user.uid,
          'communities',
          communityName,
          'binTags',
          selectedBinTag.id
        );
        await updateDoc(binTagRef, { active: activeStatus });
        
        const updatedBinTags = binTags.map((binTag) =>
          binTag.id === selectedBinTag.id ? { ...binTag, active: activeStatus } : binTag
        );
        setBinTags(updatedBinTags);
        setSelectedBinTag({...selectedBinTag, active: activeStatus});
        setChangesMade(false);
        setOriginalActiveStatus(activeStatus);
      } catch (error) {
        console.error('Error updating bin tag:', error);
      }
    }
  };

  return (
    <div className="bin-tags-table-container">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="bin-tags-header">
            <h2>Checkpoints: {binTags.length}</h2>
            <div className="bin-tags-actions">
              {selectedBinTags.length > 0 && (
                <div className="action-buttons">
                  <button onClick={() => handleActivateDeactivate(true)} className="action-button" title="Activate">
                    Activate
                  </button>
                  <button onClick={() => handleActivateDeactivate(false)} className="action-button" title="Deactivate">
                    Deactivate
                  </button>
                  <button onClick={generatePDF} className="icon-button" title="Download QR Codes">
                    <img src={downloadIcon} alt="Download QR Codes" />
                  </button>
                  <button onClick={handleDelete} className="icon-button" title="Delete">
                    <img src={deleteIcon} alt="Delete" />
                  </button>
                </div>
              )}
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Search checkpoints"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="search-input"
                />
                <button 
                  className={`icon-button ${filterActive !== null ? (filterActive ? 'active-filter' : 'inactive-filter') : ''}`} 
                  onClick={handleFilter}
                  title={filterActive === null ? 'All' : (filterActive ? 'Active' : 'Inactive')}
                >
                  <img src={filterIcon} alt="Filter" />
                </button>
              </div>
              <button onClick={() => setModalOpen(true)} className="icon-button add-button">
                <img src={addIcon} alt="Add" />
              </button>
            </div>
          </div>
          <table className="bin-tags-table">
            <thead>
              <tr>
                <th>
                  <input 
                    type="checkbox" 
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
                {columns.map((column) => (
                  <th 
                    key={column.field} 
                    onClick={() => handleSort(column.field)}
                    className={sortConfig.key === column.field ? sortConfig.direction : ''}
                  >
                    {column.headerName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredSortedBinTags.map((binTag) => (
                <tr key={binTag.id} onClick={() => handleBinTagClick(binTag)} style={{cursor: 'pointer'}}>
                  <td onClick={(e) => e.stopPropagation()}>
                    <input 
                      type="checkbox" 
                      checked={selectedBinTags.includes(binTag.id)}
                      onChange={() => handleSelectBinTag(binTag.id)}
                    />
                  </td>
                  <td>{binTag.name}</td>
                  <td>{binTag.id}</td>
                  <td>{binTag.createdAt}</td>
                  <td>
                    <StyledChip 
                      label={binTag.active ? 'Active' : 'Inactive'} 
                      active={binTag.active}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
            {selectedBinTags.length > 0 ? (
              <>
                <h3>Confirm Deletion</h3>
                <p>Are you sure you want to delete the selected checkpoints?</p>
                <div className="modal-actions">
                  <button onClick={confirmDelete} className="bin-tag-delete-button">Yes, Delete</button>
                </div>
              </>
            ) : (
              <form onSubmit={handleAddBinTag} className="modal-form">
                <h3>Add New Checkpoint</h3>
                <label>
                  Checkpoint Name:
                  <input
                    type="text"
                    value={newBinTagName}
                    onChange={(e) => setNewBinTagName(e.target.value)}
                    required
                  />
                </label>
                <button className="main-button" type="submit">
                  Add Checkpoint
                </button>
              </form>
            )}
          </Modal>
          {selectedBinTag && (
            <Modal isOpen={true} onClose={() => setSelectedBinTag(null)}>
              <div className="bin-tag-details">
                <img
                  src={selectedBinTag.qrCodeURL}
                  alt={`QR code for ${selectedBinTag.name}`}
                  className="qr-code-large"
                />
                <p><strong>Name:</strong> {selectedBinTag.name}</p>
                <p><strong>ID:</strong> {selectedBinTag.id}</p>
                <p><strong>Building:</strong> {selectedBinTag.building}</p>
                <p><strong>Created At:</strong> {selectedBinTag.createdAt}</p>
                <p>
                  <strong>Status:</strong>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={activeStatus}
                      onChange={handleStatusChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </p>
                <div className="copy-url">
                  <CopyToClipboard text={selectedBinTag.url} onCopy={() => setCopySuccess('Copied!')}>
                    <button className="copy-button">
                      <img src={copyIcon} alt="Copy" className="copy-icon" />
                      Copy URL
                    </button>
                  </CopyToClipboard>
                  {copySuccess && <span className="copy-success">{copySuccess}</span>}
                </div>
                <div className="edit-buttons">
                  <button className="bin-tag-delete-button" onClick={() => handleDelete()}>
                    Delete
                  </button>
                  {changesMade && (
                    <button className="bin-tag-save-button" onClick={handleSaveChanges}>
                      Save
                    </button>
                  )}
                </div>
              </div>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

export default BinTagsTable;
